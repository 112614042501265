import request from "@/api/service";

const api = {
   // 集团收入统计
   getGroupStatistics:'/order/incomeStatement/getGroupStatistics',
   // 酒店收入统计
   getIncomeList: '/order/incomeStatement/getIncomeList',

   // 查询集团默认抽佣比例
   getDefaultCompanyCommission:'/hotel/companyCommission/getDefaultCompanyCommission/',
   // 集团默认抽佣设置
   setDefaultCompanyCommission:'/hotel/companyCommission/setDefaultCompanyCommission',
   // 集团抽佣设置
   setCompanyCommission:'/hotel/companyCommission/setCompanyCommission',
   // 设置酒店营业状态
   setHotelBusinessStatus:'/hotel/companyCommission/setHotelBusinessStatus',

   // 提现
   withdrawals:'/order/withdrawalsRecord',
   // 提现记录
   withdrawalsRecord:'/order/withdrawalsRecord/getPage',
   // 客房订单收入详情
   getIncomeOrderList:'/order/incomeStatement/getIncomeOrderList',
   // 预售卷订单收入详情
   getCouponPresellOrderList:'/order/incomeStatement/getCouponPresellOrderList'
}
// 集团收入统计
export function getGroupStatistics(params) {
   return request({
      url: api.getGroupStatistics,
      method: 'GET',
      params
   })
}
// 酒店收入统计
export function getIncomeList(params) {
   return request({
      url: api.getIncomeList,
      method: 'GET',
      params
   })
}

// 查询集团默认抽佣比例
export function getDefaultCompanyCommission(companyId) {
   return request({
      url: api.getDefaultCompanyCommission + companyId,
      method: 'POST',
   })
}
// 集团默认抽佣设置
export function setDefaultCompanyCommission(data) {
   return request({
      url: api.setDefaultCompanyCommission,
      method: 'POST',
      data:data
   })
}
// 集团抽佣设置
export function setCompanyCommission(data) {
   return request({
      url: api.setCompanyCommission,
      method: 'POST',
      data:data
   })
}

// 设置酒店营业状态
export function setHotelBusinessStatus(data) {
   return request({
      url: api.setHotelBusinessStatus,
      method: 'POST',
      data:data
   })
}

// 提现
export function withdrawals(data) {
   return request({
      url: api.withdrawals,
      method: 'POST',
      data:data
   })
}
// 提现记录
export function withdrawalsRecord(params) {
   return request({
      url: api.withdrawalsRecord,
      method: 'GET',
      params
   })
}

// (客房/预售卷)订单收入详情
export function getIncomeOrderList(state,params) {
   return request({
      url: state === 'IncomeOrder' ? api.getIncomeOrderList : api.getCouponPresellOrderList,
      method: 'GET',
      params
   })
}

