<template>
   <section class="cont groupStatistics">
      <Breadcrumb :crumbs="crumbs"/>
      <el-row class="content-box">
         <div class="revenueBox">
            <div class="revenueBox-item companyRevenue"> 集团佣金收入 <div class="revenueBox-item-amount" >{{revenueList.companyRevenue ? revenueList.companyRevenue : '0'}} 元</div> </div>
            <div class="revenueBox-item  totalSale m-left-60"> 集团销售总额 <div class="revenueBox-item-amount">{{revenueList.totalSale ? revenueList.totalSale : '0'}} 元</div></div>
            <div style="margin: auto 0 0 0;"><el-button class="bg-gradient m-left-60" @click="handleBut('defaultSetScale')"> 默认抽佣比例 </el-button></div>
         </div>
         <!-- table -->
         <h3>门店收入</h3>
         <el-table fit  v-loading="loading" :data="tableData" style="width: 100%;"  height="600"
                   @cell-click="(row, column)=>{ storeRevenueCheck(column.property,row) }">
            <el-table-column label="序号" type="index" align="center"></el-table-column>
            <el-table-column label="门店名称" prop="hotelName" align="center"></el-table-column>
            <el-table-column label="销售总额" prop="totalSales" align="center" width="120"></el-table-column>
            <el-table-column label="门店收入" prop="hotelRevenue" align="center" width="120">
               <template slot-scope="scope"> <span class="cursor-p blueColor">{{scope.row.hotelRevenue}}</span> </template>
            </el-table-column>
            <el-table-column label="剩余未核销" prop="totalNotWrittenOff" align="center" width="120"></el-table-column>
            <el-table-column label="剩余可提现" prop="totalWithdrawal" align="center" width="120"></el-table-column>
            <el-table-column label="状态" prop="businessStatus" align="center"  width="120">
               <template slot-scope="scope">{{scope.row.businessStatus === 'DO_BUSINESS' ? '营业' : scope.row.businessStatus === 'PAUSE' ? '暂停' : '-'}}</template>
            </el-table-column>
            <el-table-column label="抽佣比例" prop="companyCommissionRate" align="center" width="120">
               <template slot-scope="scope">{{scope.row.companyCommissionRate * 100}}%</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
               <template slot-scope="scope">
                  <el-button type="text" @click="handleBut('setScale',scope.row)">设置比例</el-button>
                  <el-button type="text" @click="handleBut('closeBusiness',scope.row)">{{ scope.row.businessStatus === 'DO_BUSINESS' ? '暂停' : '恢复' }}营业</el-button>
                  <el-button type="text" @click="handleBut('withdraw',scope.row)">提现</el-button>
                  <el-button type="text" @click="handleBut('withdrawRecord',scope.row)">提现记录</el-button>
               </template>
            </el-table-column>
         </el-table>
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>

      <el-dialog :title="dialogTitle | filterDialogTitle(that)"
                 :visible.sync="dialogVisible" :width="dialogTitle === 'withdrawRecord' ? '40%' : '25%'" :before-close="handleClose">
         <!-- 默认抽佣比例/设置比例  -->
         <div v-if="dialogTitle === 'defaultSetScale' || dialogTitle === 'setScale'" style="text-align: center">
            <el-input-number v-model="companyCommissionRate" :min="1" :max="100" id="setScaleId"></el-input-number>
         </div>
         <!-- (暂停/恢复)营业 -->
         <div v-if="dialogTitle === 'closeBusiness'" style="color: red;">
            {{rowData.businessStatus === 'DO_BUSINESS' ? '暂停营业后，该门店将不' : '恢复营业后，该门店将'}}在集团订房小程序显示。
         </div>
         <!-- 提现 -->
         <div v-if="dialogTitle === 'withdraw'" style="display: flex;">
            <div style="margin: auto 0;">输入提现额</div>
            <el-input class="width-240 m-left-10" v-model="withdrawalAmount" @input="withdrawalAmount=withdrawalAmount.replace(/\D/g,'')" placeholder="请输入内容"></el-input>
         </div>
         <!-- 提现记录 -->
         <div v-if="dialogTitle === 'withdrawRecord'">
            <el-table fit :data="withdrawTableData" style="width: 100%" >
               <el-table-column label="序号" type="index" align="center"></el-table-column>
               <el-table-column label="提现时间" prop="createTime" align="center"></el-table-column>
               <el-table-column label="提现金额" prop="withdrawalAmount" align="center"></el-table-column>
               <el-table-column label="操作人" prop="operatorName" align="center"></el-table-column>
            </el-table>
         </div>
         <span slot="footer" class="dialog-footer">
           <div>
              <el-button :type="dialogTitle === 'withdrawRecord' ? 'primary': ''" @click="handleClose">{{dialogTitle !== 'withdrawRecord' ? '取 消' : '关 闭'}}</el-button>
              <el-button v-if="dialogTitle !== 'withdrawRecord'" type="primary" @click="dialogFinish()">确 定</el-button>
           </div>
         </span>
      </el-dialog>
      <!-- 门店收入 -->
      <el-dialog title="门店收入" :visible.sync="storeRevenueVisible" width="55%" :before-close="storeRevenueClose">

         <el-tabs v-model="activeName"  @tab-click="handleClick">
            <el-tab-pane v-for="(item,index) in {IncomeOrder:'客房订单',CouponPresellOrder:'预售券订单'}" :key="index" :label="item" :name="index"></el-tab-pane>
         </el-tabs>
         <div>
            <el-table border :data="storeTableData" style="width: 100%">
               <el-table-column label="序号" type="index" align="center" width="49"></el-table-column>
               <el-table-column label="订单编号" prop="orderNO" align="center"></el-table-column>
               <el-table-column label="时间" prop="createTime" align="center"></el-table-column>
               <el-table-column label="订单金额" prop="actualAmount" align="center" width="100"></el-table-column>
               <el-table-column label="门店收入" prop="hotelRevenue" align="center" width="100"></el-table-column>
               <el-table-column label="营销抽成" prop="marketingFee" align="center" width="100"></el-table-column>
               <el-table-column label="集团收入" prop="companyRevenue" align="center" width="100"></el-table-column>
               <el-table-column label="订单状态" prop="orderStatus" align="center" width="80">
                  <template slot-scope="scope">{{ scope.row.orderStatus | filterOrderStatus }}</template>
               </el-table-column>
            </el-table>
            <pagination :total="storeRevenueTotal" :page-size="storeRevenueLimit" @handleSizeChangeSub="storeRevenuePageChange" @handleCurrentChangeSub="storeRevenueHandlePaging"/>
         </div>
         <span slot="footer" class="dialog-footer">
           <el-button @click="storeRevenueClose">关闭</el-button>
<!--           <el-button type="primary" @click="storeRevenueFinish()">主要按钮</el-button>-->
         </span>
      </el-dialog>
   </section>
</template>

<script>
import { getGroupStatistics , getIncomeList , getDefaultCompanyCommission , setDefaultCompanyCommission ,
   setCompanyCommission , setHotelBusinessStatus ,withdrawals , withdrawalsRecord , getIncomeOrderList} from "@/api/GroupManagement"
import {mapState} from "vuex";
export default {
   data(){
      return{
         that:this,
         crumbs: new Map([
            ['集团管理'], ['统计报表'], ['集团收入报表']
         ]),
         revenueList:{},

         tableData:[],
         loading:true,

         dialogVisible:false,       // 对话框是否显示
         dialogTitle:'',
         companyCommissionRate:3,   // 比例
         withdrawalAmount:'',       // 提现额
         withdrawTableData:[],      // 提现记录
         withdrawalsTotal: 0,
         withdrawalsPage: 1,
         withdrawalsLimit:10,

         rowData:'',

         activeName:'IncomeOrder',   //
         // 门店收入
         storeRevenueVisible:false,
         storeTableData:[],
         storeRevenueRow:'',
         storeRevenueTotal: 0,
         storeRevenuePage:1,
         storeRevenueLimit:10,


         total: 0,                // 用户列表总条目数
         page: 1,                 // 当前页 默认第一页
         limit:10,                // 每页显示数


      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   watch:{
      hotelInfo(newVal,oldVal){
         if(oldVal && newVal.id !== oldVal.id){
            this.getIncomeLists()
            this.getGroupStatistic()
         }
      }
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1 // 每页显示数
      this.getIncomeLists()
      this.getGroupStatistic()
   },
   methods:{
      // 集团收入统计
      getGroupStatistic(){
         getGroupStatistics({companyId:this.hotelInfo.storeId}).then(res=>{
            if(res.success){
               this.revenueList = res.data
            }
         })
      },
      // 获取门店收入统计
      getIncomeLists(){
         getIncomeList({limit:this.limit,page:this.page,companyId:this.hotelInfo.storeId}).then(res=>{
            if(res.success){
               this.loading = false
               this.tableData = res.records
               this.total = res.total
            }
         })
      },

      handleBut(state,row){
         this.dialogTitle = state
         if(this.dialogTitle === 'defaultSetScale'){
            this.inputSuffix()
            // 请求获取默认抽佣比例
            getDefaultCompanyCommission(this.hotelInfo.storeId).then(res=>{
               if(res.success){
                  this.companyCommissionRate = res.data * 100
               }
            })
         }
         if(this.dialogTitle === 'setScale'){
            this.inputSuffix()
            this.rowData = row
            this.companyCommissionRate = row.companyCommissionRate * 100
         }
         if(this.dialogTitle === 'closeBusiness') this.rowData = row
         if(this.dialogTitle === 'withdraw' ) this.rowData = row
         if(this.dialogTitle === 'withdraw' && !row.totalWithdrawal) return this.$message('暂无剩余可提现额!')

         if(this.dialogTitle === 'withdrawRecord'){
            let params = {
               hotelId:row.hotelId,
               limit:this.withdrawalsLimit,
               page:this.withdrawalsPage
            }
            withdrawalsRecord(params).then(res=>{
               if(res.success){
                  this.withdrawTableData = res.records
                  this.withdrawalsTotal = res.total
               }
            })
         }
         this.dialogVisible = true
      },
      // 关闭
      handleClose(){
         this.dialogVisible = false
         this.withdrawalAmount = ''
      },
      // 确定
      dialogFinish(){
         // 默认抽佣比例
         let params = {}
         if(this.dialogTitle === 'defaultSetScale'){
            params = {
               companyId:this.hotelInfo.storeId,
               companyCommissionRate:this.companyCommissionRate / 100
            }
            setDefaultCompanyCommission(params).then(res=>{
               if(res.success){
                  this.$message.success('默认抽佣比例设置成功！')
                  this.handleClose()
                  this.getIncomeLists()
               }
            })
         }

         // 设置比例
         if(this.dialogTitle === 'setScale'){
            params = {
               hotelId:this.rowData.hotelId,
               companyCommissionRate:this.companyCommissionRate /100
            }
            setCompanyCommission(params).then(res=>{
               if(res.success){
                  this.$message.success('设置比例成功')
                  this.handleClose()
                  this.getIncomeLists()
               }
            })
         }

         // (暂停/恢复)营业
         if(this.dialogTitle === 'closeBusiness'){
            params = {
               hotelId:this.rowData.hotelId,
               businessStatus:this.rowData.businessStatus === 'DO_BUSINESS' ? 'PAUSE' : 'DO_BUSINESS'
            }
            setHotelBusinessStatus(params).then(res=>{
               if(res.success){
                  this.$message.success(`${this.rowData.businessStatus === 'DO_BUSINESS' ? '已暂停' : '已恢复'}营业`)
                  this.handleClose()
                  this.getIncomeLists()
               }
            })
         }

         // 提现
         if(this.dialogTitle === 'withdraw'){
            if(!this.withdrawalAmount) return this.$message.error('请输入提现额！')
            if(this.withdrawalAmount > this.rowData.totalWithdrawal) return this.$message.error(`输入的提现额已超出当前可提现额，请重新输入等于小于${this.rowData.totalWithdrawal}！`)
            params = {
               companyId:this.hotelInfo.storeId,
               hotelId:this.hotelInfo.id,
               withdrawalAmount:this.withdrawalAmount
            }
            withdrawals(params).then(res=>{
               if(res.success){
                  this.$message.success('提现成功')
                  this.handleClose()
                  this.getIncomeLists()
               }
            })
         }
      },

      // 门店收入 查看
      storeRevenueCheck(column,row,column1){
         console.log(column1)
         if(row) { this.storeRevenueRow = row }
         if(column === 'hotelRevenue'){
            let params = {
               companyId:this.storeRevenueRow.companyId,
               hotelId:this.storeRevenueRow.hotelId,
               limit:this.storeRevenueLimit,
               page:this.storeRevenuePage
            }
            getIncomeOrderList(this.activeName,params).then(res=>{
               if(res.success){
                  this.storeRevenueVisible = true
                  this.storeTableData = res.records.records
                  this.storeRevenueTotal = res.records.total
               }
            })
         }
      },

      // 门店收入 关闭
      storeRevenueClose(){
         this.storeRevenueVisible = false
         this.activeName = "IncomeOrder"
         this.storeRevenueTotal = 0
         this.storeRevenuePage = 1
         this.storeRevenueLimit = 10
      },

      // tabs切换
      handleClick(){ this.storeRevenueCheck('hotelRevenue') },

      // 门店收入 主要按钮
      // storeRevenueFinish(){},

      // input添加“%”后缀
      inputSuffix(){
         const span = document.createElement('span');
         const innerspan = document.createElement('span');
         span.setAttribute("class","el-input__suffix setSuffix-box");
         innerspan.setAttribute("class","el-input__suffix-inner");
         span.append(innerspan)
         innerspan.append('%')
         this.$nextTick(() =>{
            document.getElementById('setScaleId').lastElementChild.prepend(span);
         })
      },

      // 改变每页数
      pageChange(num) {
         this.limit = num;
         this.getIncomeLists();
      },
      // 改变当前页
      handlePaging(num) {
         this.page = num;
         this.getIncomeLists();
      },

      storeRevenuePageChange(num) {
         this.storeRevenueLimit = num;
         this.storeRevenueCheck('hotelRevenue');
      },
      storeRevenueHandlePaging (num) {
         this.storeRevenuePage = num;
         this.storeRevenueCheck('hotelRevenue');
      },
   },
   filters:{
      filterDialogTitle(val,that){
         switch (val){
            case 'defaultSetScale' :
               return '默认抽佣比例';
            case 'setScale' :
               return '设置比例';
            case 'closeBusiness' :
               return `${that.rowData.businessStatus === "DO_BUSINESS" ? '暂停' : '恢复'}营业`;
            case 'withdraw' :
               return '提现';
            case 'withdrawRecord' :
               return '提现记录';
         }
      },
      filterOrderStatus(val){
         switch (val){
            // 客房订单
            case "NOT_CHECKIN":
               return '未入住';
            case "CHECKIN":
               return '已入住';
            case "CHECKOUT":
               return '已退房';

            // 预售券订单
            case "HAVE_PAID":
               return '已付款';
            case "PENDING_PAYMENT":
               return '待付款';
            case "REFUNDED":
               return '已退款';

            case "CANCEL":
               return '已取消';
         }
      }
   }
}
</script>
<style scoped lang="scss">
.groupStatistics{
   .blueColor{
      color: #3a8ee6;
   }
   .revenueBox{
      display: flex;
      margin-bottom: 30px;
      color: white;
      &-item{
         //padding: 10px;
         padding: 30px 40px;
         border-radius: 8px;
         width: 200px;
         font-size: 18px;
         &-amount{
            text-align: center;
            margin-top: 10px;
            font-size: 28px;
            font-weight: bold;
         }
      }
      .companyRevenue{ background-image: linear-gradient(90deg, rgb(0, 111, 255), rgb(24, 154, 255)); }
      .totalSale{ background-image: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188)); }
   }
   #setScaleId{
      ::v-deep .setSuffix-box{
         right:60px!important;
      }
   }
}
</style>